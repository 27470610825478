:root {
    --toastify-toast-min-height: 30px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.Toastify__toast-container--top-right {
    top: 4em;
}

.spinner {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 1101;
    margin-top: -30px;
    margin-left: -30px;
}

.even {
    background-color: #eeeeee;
}

.bError {
    border-left: solid 2px red;
}

.hide {
    display: none !important;
}

blockquote {
    border-radius: 10px;
    border: 1px solid;
    border-left: 8px solid;
    border-color: rgb(255 220 72);
    background-color: rgb(255 249 235);
    padding: 10px 20px;
    margin: 20px 0;
}

.p-wrap {
    white-space: pre;
    display: table-cell;
}

#site-select.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    letter-spacing: 0.05em;
    padding: 13px 14px;
}
